<template>
  <div class="container">
    <div>
      <h3>SAD ACTITUDINAL</h3>
      <h5>
        Esta pregunta se oriente a conocer tus motivaciones, postura vocacional
        y lo que esperas de la nueva casa de estudio.
      </h5>
      <!-- Pregunta 1 -->
      <div class="m-4">
        <div class="container">
          <p class="text-justify">
            1. Respecto a las siguientes afirmaciones, expresa tu opinión
            marcando A, B, C o D según considere en cada caso:
          </p>
          <div class="text-justify">
            <strong>A:</strong> En total desacuerdo, muy malo, casi inexistente.
          </div>
          <div class="text-justify">
            <strong>B:</strong> Medianamente de acuerdo, mediocre, medianamente
            existente.
          </div>
          <div class="text-justify">
            <strong>C:</strong> De acuerdo, bueno, altamente posible.
          </div>
          <div class="text-justify">
            <strong>D:</strong> Totalmente de acuerdo, excelente, sobresaliente.
          </div>
          <table>
            <thead>
              <tr>
                <th class="w-100">AFIRMACIÓN</th>
                <th><strong>A</strong></th>
                <th><strong>B</strong></th>
                <th><strong>C</strong></th>
                <th><strong>D</strong></th>
              </tr>
              <tr>
                <td>
                  Sabré desempeñarme adecuadamente en mis futuros trabajos.
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_1"
                    :checked="sad._1_1 == 1"
                    :value="1"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_1"
                    :checked="sad._1_1 == 2"
                    :value="2"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_1"
                    :checked="sad._1_1 == 3"
                    :value="3"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_1"
                    :checked="sad._1_1 == 4"
                    :value="4"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
              </tr>
              <tr>
                <td>
                  Me matriculé en esta carrera porque mis amigos (as) ingresaron
                  a estudiar a esta Universidad.
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_2"
                    :checked="sad._1_2 == 1"
                    :value="1"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_2"
                    :checked="sad._1_2 == 2"
                    :value="2"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_2"
                    :checked="sad._1_2 == 3"
                    :value="3"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_2"
                    :checked="sad._1_2 == 4"
                    :value="4"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
              </tr>
              <tr>
                <td>Estudiar en la Universidad me permitirá aprender a investigar.</td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_3"
                    :checked="sad._1_3 == 1"
                    :value="1"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_3"
                    :checked="sad._1_3 == 2"
                    :value="2"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_3"
                    :checked="sad._1_3 == 3"
                    :value="3"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_3"
                    :checked="sad._1_3 == 4"
                    :value="4"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
              </tr>
              <tr>
                <td>Mis estudios universitarios me permitirán desarrollar habilidades para aprender en forma autónoma.</td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_4"
                    :checked="sad._1_4 == 1"
                    :value="1"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_4"
                    :checked="sad._1_4 == 2"
                    :value="2"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_4"
                    :checked="sad._1_4 == 3"
                    :value="3"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_4"
                    :checked="sad._1_4 == 4"
                    :value="4"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
              </tr>
              <tr>
                <td>Esta carrera me ayudará a mejorar mis condiciones de vida.</td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_5"
                    :checked="sad._1_5 == 1"
                    :value="1"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_5"
                    :checked="sad._1_5 == 2"
                    :value="2"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_5"
                    :checked="sad._1_5 == 3"
                    :value="3"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_5"
                    :checked="sad._1_5 == 4"
                    :value="4"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
              </tr>
              <tr>
                <td>Mis estudios me permitirán aprender a trabajar en equipo.</td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_6"
                    :checked="sad._1_6 == 1"
                    :value="1"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_6"
                    :checked="sad._1_6 == 2"
                    :value="2"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_6"
                    :checked="sad._1_6 == 3"
                    :value="3"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_6"
                    :checked="sad._1_6 == 4"
                    :value="4"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
              </tr>
              <tr>
                <td>El trabajo que obtenga afectará mi vida personal.</td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_7"
                    :checked="sad._1_7 == 1"
                    :value="1"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_7"
                    :checked="sad._1_7 == 2"
                    :value="2"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_7"
                    :checked="sad._1_7 == 3"
                    :value="3"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_7"
                    :checked="sad._1_7 == 4"
                    :value="4"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
              </tr>
              <tr>
                <td>Me será útil estudiar en la Universidad.</td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_8"
                    :checked="sad._1_8 == 1"
                    :value="1"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_8"
                    :checked="sad._1_8 == 2"
                    :value="2"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_8"
                    :checked="sad._1_8 == 3"
                    :value="3"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_8"
                    :checked="sad._1_8 == 4"
                    :value="4"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
              </tr>
              <tr>
                <td>Mis estudios universitarios me permitirán aumentar mis conocimientos prácticos.</td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_9"
                    :checked="sad._1_9 == 1"
                    :value="1"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_9"
                    :checked="sad._1_9 == 2"
                    :value="2"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_9"
                    :checked="sad._1_9 == 3"
                    :value="3"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_9"
                    :checked="sad._1_9 == 4"
                    :value="4"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>La carrera que estudiaré me permitirá lograr una formación profesional completa.</td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_10"
                    :checked="sad._1_10 == 1"
                    :value="1"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_10"
                    :checked="sad._1_10 == 2"
                    :value="2"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_10"
                    :checked="sad._1_10 == 3"
                    :value="3"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_10"
                    :checked="sad._1_10 == 4"
                    :value="4"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
              </tr>
              <tr>
                <td>Mis estudios me permitirán ampliar mis conocimientos teóricos.</td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_11"
                    :checked="sad._1_11 == 1"
                    :value="1"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_11"
                    :checked="sad._1_11 == 2"
                    :value="2"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_11"
                    :checked="sad._1_11 == 3"
                    :value="3"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_11"
                    :checked="sad._1_11 == 4"
                    :value="4"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
              </tr>
              <tr>
                <td>Contribuiré al mejoramiento de la sociedad.</td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_12"
                    :checked="sad._1_12 == 1"
                    :value="1"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_12"
                    :checked="sad._1_12 == 2"
                    :value="2"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_12"
                    :checked="sad._1_12 == 3"
                    :value="3"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_12"
                    :checked="sad._1_12 == 4"
                    :value="4"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
              </tr>
              <tr>
                <td>Mis estudios universitarios me permitirán conseguir un buen trabajo.</td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_13"
                    :checked="sad._1_13 == 1"
                    :value="1"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_13"
                    :checked="sad._1_13 == 2"
                    :value="2"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_13"
                    :checked="sad._1_13 == 3"
                    :value="3"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_13"
                    :checked="sad._1_13 == 4"
                    :value="4"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
              </tr>
              <tr>
                <td>La profesión que estudiaré me entregará los aprendizajes necesarios para mi desempeño laboral.</td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_14"
                    :checked="sad._1_14 == 1"
                    :value="1"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_14"
                    :checked="sad._1_14 == 2"
                    :value="2"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_14"
                    :checked="sad._1_14 == 3"
                    :value="3"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_14"
                    :checked="sad._1_14 == 4"
                    :value="4"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
              </tr>
              <tr>
                <td>La carrera que estudiaré me ayudará a incorporarme exitosamente en el mundo laboral.</td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_15"
                    :checked="sad._1_15 == 1"
                    :value="1"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_15"
                    :checked="sad._1_15 == 2"
                    :value="2"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_15"
                    :checked="sad._1_15 == 3"
                    :value="3"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_15"
                    :checked="sad._1_15 == 4"
                    :value="4"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
              </tr>
              <tr>
                <td>Me considero preparado para realizar estudios universitarios.</td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_16"
                    :checked="sad._1_16 == 1"
                    :value="1"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_16"
                    :checked="sad._1_16 == 2"
                    :value="2"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_16"
                    :checked="sad._1_16 == 3"
                    :value="3"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_16"
                    :checked="sad._1_16 == 4"
                    :value="4"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
              </tr>
              <tr>
                <td>Estudiaré esta carrera porque mis padres consideran que es lo mejor para mí.</td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_17"
                    :checked="sad._1_17 == 1"
                    :value="1"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_17"
                    :checked="sad._1_17 == 2"
                    :value="2"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_17"
                    :checked="sad._1_17 == 3"
                    :value="3"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_17"
                    :checked="sad._1_17 == 4"
                    :value="4"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
              </tr>
              <tr>
                <td>Realmente quiero estudiar aquello que escogí.</td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_18"
                    :checked="sad._1_18 == 1"
                    :value="1"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_18"
                    :checked="sad._1_18 == 2"
                    :value="2"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_18"
                    :checked="sad._1_18 == 3"
                    :value="3"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                    v-model="sad._1_18"
                    :checked="sad._1_18 == 4"
                    :value="4"
                    :disabled="!allows_crud"
                  ></b-form-checkbox>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- Pregunta 2 -->
      <h3>SAD Cognoscente Verbal</h3>
        <h5>
          Estas preguntas se orientan a conocer el desarrollo de tus
          habilidades verbales.
        </h5>
      <div class="m-4">
        <div class="container">
          <p class="text-justify">2. Lee el siguiente texto y responde.</p>
          <template v-if="allows_crud">
            <b-form-invalid-feedback :state="Number.isInteger(sad._2)">Debe seleccionar una opción.</b-form-invalid-feedback>
          </template>
          <p class="text-justify description-p">
            “Nadie habrá dejado de observar que con frecuencia el suelo se
            pliega de manera tal que una parte sube en ángulo recto con el plano
            del suelo, y luego la parte siguiente se coloca paralela a este
            plano, para dar paso a una nueva perpendicular, conducta que se
            repite en espiral o en línea quebrada hasta alturas sumamente
            variables. Agachándose y poniendo la mano izquierda en una de las
            partes verticales, y la derecha en la horizontal correspondiente, se
            está en posesión momentánea de un peldaño o escalón”(…)
            <br />
            <strong>Fuente:</strong>
            <em class="text-primary">
              ciudadseva.com/texto/instrucciones-para-subir-una-escalera/</em
            >
          </p>
          <p class="text-justify">El texto anterior se refiere a una:</p>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._2" :checked="sad._2 == 1" :value="1"
                    :disabled="!allows_crud"
              >a. Definición.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._2" :checked="sad._2 == 2" :value="2"
                    :disabled="!allows_crud"
              >b. Argumentación.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._2" :checked="sad._2 == 3" :value="3"
                    :disabled="!allows_crud"
              >c. Secuencia de hechos.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._2" :checked="sad._2 == 4" :value="4"
                    :disabled="!allows_crud"
              >d. Clasificación.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._2" :checked="sad._2 == 5" :value="5"
                    :disabled="!allows_crud"
              >e. Descripción.</b-form-checkbox
            >
          </div>
        </div>
      </div>
      <!-- Pregunta 3 -->
      <div class="m-4">
        <div class="container">
          <p class="text-justify">
            3. ¿Qué elemento(s) debes tener presente(s) para comparar dos
            objetos?
          </p>
          <template v-if="allows_crud">
            <b-form-invalid-feedback :state="Number.isInteger(sad._3)">Debe seleccionar una opción.</b-form-invalid-feedback>
          </template>
          <div class="text-justify">I. Agruparlos.</div>
          <div class="text-justify">II. Denominarlos.</div>
          <div class="text-justify">III. Seleccionar características.</div>
          <div class="text-justify">
            IV. Establecer objetivo de la comparación.
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._3" :checked="sad._3 == 1" :value="1"
                    :disabled="!allows_crud"
              >a. Sólo I.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._3" :checked="sad._3 == 2" :value="2"
                    :disabled="!allows_crud"
              >b. I, II.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._3" :checked="sad._3 == 3" :value="3"
                    :disabled="!allows_crud"
              >c. II y III.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._3" :checked="sad._3 == 4" :value="4"
                    :disabled="!allows_crud"
              >d. II, III y IV.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._3" :checked="sad._3 == 5" :value="5"
                    :disabled="!allows_crud"
              >e. I, II, III y IV.</b-form-checkbox
            >
          </div>
        </div>
      </div>
      <!-- Pregunta 4 -->
      <div class="m-4">
        <div class="container">
          <p class="text-justify">
            4. Se forman grupos con los siguientes objetos:
          </p>
          <template v-if="allows_crud">
            <b-form-invalid-feedback :state="Number.isInteger(sad._4)">Debe seleccionar una opción.</b-form-invalid-feedback>
          </template>
          <div class="text-justify">Grupo 1: perro y vaca</div>
          <div class="text-justify">Grupo 2: silla y cama</div>
          <div class="text-justify">Grupo 3: pan y agua</div>
          <div class="text-justify">Grupo 4: plato y tenedor</div>
          <p class="text-justify">Las agrupaciones se han realizado por:</p>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._4" :checked="sad._4 == 1" :value="1"
                    :disabled="!allows_crud"
              >a. Características esenciales.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._4" :checked="sad._4 == 2" :value="2"
                    :disabled="!allows_crud"
              >b. Similitudes externas.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._4" :checked="sad._4 == 3" :value="3"
                    :disabled="!allows_crud"
              >c. Utilidad.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._4" :checked="sad._4 == 4" :value="4"
                    :disabled="!allows_crud"
              >d. Orden aleatorio.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._4" :checked="sad._4 == 5" :value="5"
                    :disabled="!allows_crud"
              >e. Coincidencia en el lugar.</b-form-checkbox
            >
          </div>
        </div>
      </div>
      <!-- Pregunta 5 -->
      <div class="m-4">
        <div class="container">
          <p class="text-justify">5. Lee el siguiente texto y responde.</p>
          <template v-if="allows_crud">
            <b-form-invalid-feedback :state="Number.isInteger(sad._5)">Debe seleccionar una opción.</b-form-invalid-feedback>
          </template>
          <p class="text-justify description-p">
            Desde el punto de vista de la biología la vida se considera como la
            capacidad de nacer, respirar, desarrollarse, procrear, evolucionar y
            morir. Además, para que haya vida desde esta óptica, es necesario
            que haya un intercambio de materia y energía.
            <br />
            <strong>Fuente:</strong>
            <em class="text-primary">
              http://lavidafelix.blogspot.cl/p/blog-page.html</em
            >
          </p>
          <p class="text-justify">El texto consiste en una:</p>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._5" :checked="sad._5 == 1" :value="1"
                    :disabled="!allows_crud"
              >a. Clasificación.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._5" :checked="sad._5 == 2" :value="2"
                    :disabled="!allows_crud"
              >b. Argumentación.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._5" :checked="sad._5 == 3" :value="3"
                    :disabled="!allows_crud"
              >c. Comparación.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._5" :checked="sad._5 == 4" :value="4"
                    :disabled="!allows_crud"
              >d. Descripción.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._5" :checked="sad._5 == 5" :value="5"
                    :disabled="!allows_crud"
              >e. Definición.</b-form-checkbox
            >
          </div>
        </div>
      </div>
      <!-- Encabezado de Preguntas 6 y 7 -->
      <div class="m-4">
        <div class="container">
          <p class="text-justify">
            Lee el siguiente texto y responde las preguntas 6 y 7.
          </p>
          <p class="text-justify description-p">
            <strong>El uso de internet en los adolescentes.</strong>
            <br />
            <br />
            Internet se ha convertido hoy día en una herramienta indispensable
            en la vida de las personas. Sería difícil, especialmente para los
            más jóvenes, concebir un mundo en el cual “no estemos conectados”.
            <br />
            <br />
            Ingo Lackerbauer, en su libro "Internet", señala que la importancia
            de internet en el futuro desborda todo lo acontecido hasta ahora, se
            está convirtiendo en el "medio de comunicación global".
            <br />
            <br />
            <u>No hace falta explicar</u> con detalles los beneficios de este
            maravilloso invento tecnológico. Nos permite educarnos, conocer,
            disfrutar. Es decir, es una herramienta multiuso. Precisamente, es
            este uso el que puede volverse negativo.
            <u>Estamos hablando de la adicción al internet</u>. Muchos jóvenes
            pasan una gran parte del día navegando por páginas, publicando en
            las redes sociales, o viendo videos en YouTube.
            <br />
            <br />
            Usar el internet para el entretenimiento no es algo malo en sí.
            <u>Lo malo es abusar</u>. El mundo de la web está plagado de
            conocimientos muy útiles, lo ideal sería también utilizarse en esa
            faceta, y que no sea solo como manera de ocio.
            <br />
            <br />
            ¿Cuáles son los perjuicios que puede acarrear la adicción a
            internet? Debido a que el adolescente pasa un tiempo considerable
            frente al ordenador, una de las mayores consecuencias es la pérdida
            de una vida social activa.
            <br />
            <br />
            Es probable que pierda el contacto que tenga con sus amigos más
            cercanos, y pasé más tiempo con los amigos “virtuales”.
            <br />
            <br />
            <strong>Fuente:</strong>
            <em class="text-primary">
              https://laliteraturayyo.jimdo.com/lo-que-he-aprendido/</em
            >
          </p>
        </div>
      </div>
      <!-- Pregunta 6 -->
      <div class="m-4">
        <div class="container">
          <p class="text-justify">6. El texto se refiere a la:</p>
          <template v-if="allows_crud">
            <b-form-invalid-feedback :state="Number.isInteger(sad._6)">Debe seleccionar una opción.</b-form-invalid-feedback>
          </template>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._6" :checked="sad._6 == 1" :value="1"
                    :disabled="!allows_crud"
              >a. Argumentación.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._6" :checked="sad._6 == 2" :value="2"
                    :disabled="!allows_crud"
              >b. Descripción.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._6" :checked="sad._6 == 3" :value="3"
                    :disabled="!allows_crud"
              >c. Clasificación.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._6" :checked="sad._6 == 4" :value="4"
                    :disabled="!allows_crud"
              >d. Comparación.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._6" :checked="sad._6 == 5" :value="5"
                    :disabled="!allows_crud"
              >e. Definición.</b-form-checkbox
            >
          </div>
        </div>
      </div>
      <!-- Pregunta 7 -->
      <div class="m-4">
        <div class="container">
          <p class="text-justify">7. Las palabras subrayadas corresponden a:</p>
          <template v-if="allows_crud">
            <b-form-invalid-feedback :state="Number.isInteger(sad._7)">Debe seleccionar una opción.</b-form-invalid-feedback>
          </template>
          <div class="text-justify"><strong>I</strong> Referencias.</div>
          <div class="text-justify"><strong>II</strong> Opiniones.</div>
          <div class="text-justify"><strong>III</strong> Hechos.</div>
          <div class="text-justify"><strong>IV</strong> Datos.</div>
          <div class="text-justify"><strong>V</strong> Relaciones.</div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._7" :checked="sad._7 == 1" :value="1"
                    :disabled="!allows_crud"
              >a. Solo I.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._7" :checked="sad._7 == 2" :value="2"
                    :disabled="!allows_crud"
              >b. Solo II.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._7" :checked="sad._7 == 3" :value="3"
                    :disabled="!allows_crud"
              >c. II y IV.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._7" :checked="sad._7 == 4" :value="4"
                    :disabled="!allows_crud"
              >d. IV y V.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._7" :checked="sad._7 == 5" :value="5"
                    :disabled="!allows_crud"
              >e. Solo III.</b-form-checkbox
            >
          </div>
        </div>
      </div>
      <!-- Pregunta 8 -->
      <div class="m-4">
        <div class="container">
          <p class="text-justify">8. Lee el siguiente texto y responde.</p>
          <template v-if="allows_crud">
            <b-form-invalid-feedback :state="Number.isInteger(sad._8)">Debe seleccionar una opción.</b-form-invalid-feedback>
          </template>
          <p class="text-justify description-p">
            Las obras literarias pueden agruparse atendiendo al tema o al estilo
            literario. En las librerías y bibliotecas lo hacen siguiendo el
            tema. Así se encuentran: policial, romántico, aventura y otros…
            Muchos libros pueden entrar en múltiples categorías a la vez. Por
            ejemplo, una novela de acción puede ser también un libro de ciencia
            ficción. Algunos géneros habituales fuera de la ciencia ficción son
            las autobiografías y biografías, guías y tutoriales (guías de viaje,
            tutoriales de cocina, etc.), material de referencia como las
            enciclopedias, libros de autoayuda, etc.
            <br />
            <strong>Fuente:</strong>
            <em class="text-primary">
              http://www.memoria.fahce.unlp.edu.ar/tesis/te.15/te.15.pdf</em
            >
          </p>
          <p class="text-justify">El texto anterior corresponde a una:</p>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._8" :checked="sad._8 == 1" :value="1"
                    :disabled="!allows_crud"
              >a. Comparación.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._8" :checked="sad._8 == 2" :value="2"
                    :disabled="!allows_crud"
              >b. Argumentación.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._8" :checked="sad._8 == 3" :value="3"
                    :disabled="!allows_crud"
              >c. Descripción.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._8" :checked="sad._8 == 4" :value="4"
                    :disabled="!allows_crud"
              >d. Clasificación.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._8" :checked="sad._8 == 5" :value="5"
                    :disabled="!allows_crud"
              >e. Denotación.</b-form-checkbox
            >
          </div>
        </div>
      </div>
      <!-- Pregunta 9 -->
      <div class="m-4">
        <div class="container">
          <p class="text-justify">9. Lee el siguiente texto y responde.</p>
          <template v-if="allows_crud">
            <b-form-invalid-feedback :state="Number.isInteger(sad._9)">Debe seleccionar una opción.</b-form-invalid-feedback>
          </template>
          <p class="text-justify description-p">
            Acapulco y Cancún son dos destinos de playa mexicanos que son
            paradisiacos y que atraen cada año a millones de turistas tanto
            nacionales como extranjeros, esto debido a la belleza de sus playas
            y a sus atractivos turísticos, sin embargo, ambos lugares son muy
            distintos, puesto que Cancún se encuentra en el Mar Caribe y
            Acapulco en el pacífico. El color del mar de Cancún es azul turquesa
            y sus playas cuentan con una arena blanca que parece talco, mientras
            que el agua del mar en Acapulco es azul obscuro y su arena es más
            gruesa y café. La vida nocturna en Acapulco es de los mejores
            atractivos que tiene, ya que sus bares y centros nocturnos
            garantizan mucha diversión, aunque en Cancún se pueden encontrar
            muchos parques naturales que cuentan con unos paisajes privilegiados
            y que también garantizan mucha diversión. Por lo general viajar a
            Cancún es mucho más caro que hacerlo a Acapulco y ambos lugares son
            una muy buena opción para vacacionar.
            <br />
            <strong>Fuente:</strong>
            <em class="text-primary">
              (A. 2013,03. Ejemplode.com. Obtenido 03, 2013, de
              http://www.ejemplode.com/12-clases_de_espanol/2722ejemplo_de_parrafo_...)</em
            >
          </p>
          <p class="text-justify">El texto consiste en una:</p>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._9" :checked="sad._9 == 1" :value="1"
                    :disabled="!allows_crud"
              >a. Clasificación.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._9" :checked="sad._9 == 2" :value="2"
                    :disabled="!allows_crud"
              >b. Argumentación.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._9" :checked="sad._9 == 3" :value="3"
                    :disabled="!allows_crud"
              >c. Descripción.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._9" :checked="sad._9 == 4" :value="4"
                    :disabled="!allows_crud"
              >d. Definición.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._9" :checked="sad._9 == 5" :value="5"
                    :disabled="!allows_crud"
              >e. Comparación.</b-form-checkbox
            >
          </div>
        </div>
      </div>
      <!-- Pregunta 10 -->
      <div class="m-4">
        <div class="container">
          <p class="text-justify">10. Lee el siguiente texto y responde.</p>
          <template v-if="allows_crud">
            <b-form-invalid-feedback :state="Number.isInteger(sad._10)">Debe seleccionar una opción.</b-form-invalid-feedback>
          </template>
          <p class="text-justify description-p">
            Los hongos son un grupo de organismos que incluye a los mohos, setas y levaduras. Actualmente se sabe que los hongos:
            <br />
            <ul>
              <li>No tienen raíces.</li>
              <li>No tiene clorofila y no pueden fabricar su propio alimento mediante la fotosíntesis. Son Heterótrofos.</li>
              <li>Se reproducen por esporas (son como pequeñas semillas).</li>
            </ul>
            Algunos hongos son comestibles, pero hay muchos que son altamente venenosos; pueden causar daños y algunas veces enfermedades a la materia orgánica de la que se alimentan, pero algunos hongos son explotados en la producción de comidas y bebidas (por ejemplo, las levaduras para la repostería y fabricación de cerveza) y en medicina (por ejemplo, antibióticos como la penicilina).
            <br />
            <strong>Fuente:</strong>
            <em class="text-primary">
              (A. 2013,03. Ejemplode.com. Obtenido 03, 2013, de
              http://www.ejemplode.com/12-clases_de_espanol/2722ejemplo_de_parrafo_...)</em
            >
          </p>
          <p class="text-justify">El texto consiste en una:</p>
          <div class="text-justify">I. Comparación.</div>
          <div class="text-justify">II. Descripción.</div>
          <div class="text-justify">III. Clasificación.</div>
          <div class="text-justify">IV. Definición.</div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._10" :checked="sad._10 == 1" :value="1"
                    :disabled="!allows_crud"
              >a. Sólo I y II.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._10" :checked="sad._10 == 2" :value="2"
                    :disabled="!allows_crud"
              >b. Sólo II y III.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._10" :checked="sad._10 == 3" :value="3"
                    :disabled="!allows_crud"
              >c. Sólo I, II y III.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._10" :checked="sad._10 == 4" :value="4"
                    :disabled="!allows_crud"
              >d. Sólo III.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._10" :checked="sad._10 == 5" :value="5"
                    :disabled="!allows_crud"
              >e. I, II, III y IV.</b-form-checkbox
            >
          </div>
        </div>
      </div>
      <!-- Pregunta 11 -->
      <div class="m-4">
        <div class="container">
          <p class="text-justify">11. Lee el siguiente texto y responde.</p>
          <template v-if="allows_crud">
            <b-form-invalid-feedback :state="Number.isInteger(sad._11)">Debe seleccionar una opción.</b-form-invalid-feedback>
          </template>
          <p class="text-justify description-p">
            El tema de las drogas siempre es actual y polémico. Encontraremos defensores y detractores en lo que respecta a la forma en que deben ser utilizadas por la ciudadanía. ¿Son las drogas malas? ¿Y si lo son, qué tan perjudiciales son para la salud de las personas?
            <br />
            <br />
            <u>En primer lugar, es importante aclarar el término.</u>
            <br />
            <br />
            Según el diccionario de la RAE, droga es cualquier <u>“sustancia mineral, vegetal o animal, que se emplea en la medicina, en la industria o en las bellas artes.”</u>
            <br />
            <br />
            <u>Se trata de un concepto muy amplio y vago.</u>
            <br />
            <br />
            Se podría afirmar que una droga no es mala en sí, lo que podría ocurrir es que, <u>ciertas drogas, tienen la capacidad de dañar el organismo humano</u>...
            <br />
            <strong>Fuente:</strong>
            <em class="text-primary">https://www.aboutespanol.com/</em
            >
          </p>
          <p class="text-justify">Las palabras subrayadas orientan a:</p>
          <div class="text-justify">I. Describir las drogas.</div>
          <div class="text-justify">II. Definir las drogas.</div>
          <div class="text-justify">III. Enumerar tipos de drogas.</div>
          <div class="text-justify">IV. Caracterizar las drogas.</div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._11" :checked="sad._11 == 1" :value="1"
                    :disabled="!allows_crud"
              >a. I y III.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._11" :checked="sad._11 == 2" :value="2"
                    :disabled="!allows_crud"
              >b. Sólo II.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._11" :checked="sad._11 == 3" :value="3"
                    :disabled="!allows_crud"
              >c. I, II y III.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._11" :checked="sad._11 == 4" :value="4"
                    :disabled="!allows_crud"
              >d. I, II y IV.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._11" :checked="sad._11 == 5" :value="5"
                    :disabled="!allows_crud"
              >e. I, II, III y IV.</b-form-checkbox
            >
          </div>
        </div>
      </div>
      <!-- Pregunta 12 -->
      <div class="m-4">
        <div class="container">
          <p class="text-justify">12. Lee el siguiente texto y responde.</p>
          <template v-if="allows_crud">
            <b-form-invalid-feedback :state="Number.isInteger(sad._12)">Debe seleccionar una opción.</b-form-invalid-feedback>
          </template>
          <p class="text-justify description-p">
            <strong>La diabetes y la importancia de una dieta saludable</strong>
            <br />
            <br />
            Muchas personas creen que la palabra “dieta” implica comer menos y solo aquellas comidas que no nos gustan.
            <br />
            <br />
            Se trata de un prejuicio que impera en gran parte de la sociedad.
            <br />
            <br />
            Sin embargo, dieta, realmente, significa la manera en que una persona se alimenta. Algunos tienen una dieta saludable, y otras, no tanto.
            <br />
            <br />
            ¿A quién no le gusta comer todo lo que quiera, sin tener que preocuparse de saber cuántas calorías tiene un determinado alimento? Sin embargo, si abusamos con una dieta poco nutritiva, los resultados para nuestra salud pueden ser graves.
            <br />
            <br />
            Una de las consecuencias de una mala alimentación, es la enfermedad conocida como diabetes. Se trata de una enfermedad que afecta a millones de personas en todo el mundo. Y lo peor es que, hasta ahora, no se ha podido encontrar alguna cura para este mal.
            <br />
            <br />
            La diabetes, en la definición de la Organización Mundial de la Salud (OMS) es una enfermedad crónica que aparece cuando el páncreas no produce insulina suficiente o cuando el organismo no utiliza eficazmente la insulina que produce.
            <br />
            <br />
            Dicha organización estima que existen más de 300 millones de personas afectadas. De entre las varias recomendaciones que hace la OMS para prevenir la diabetes, rescatamos la que habla de “Alcanzar y mantener un peso corporal saludable”.
            <br />
            <br />
            Es decir, mantener una dieta equilibrada es una excelente forma de luchar contra esta enfermedad.
            <br />
            <strong>Fuente:</strong>
            <em class="text-primary">https://sites.google.com/site/fpbisergiosoria/la-diabetes</em
            >
          </p>
          <p class="text-justify">El texto hace referencia a la:</p>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._12" :checked="sad._12 == 1" :value="1"
                    :disabled="!allows_crud"
              >a. Descripción.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._12" :checked="sad._12 == 2" :value="2"
                    :disabled="!allows_crud"
              >b. Clasificación.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._12" :checked="sad._12 == 3" :value="3"
                    :disabled="!allows_crud"
              >c. Comparación.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._12" :checked="sad._12 == 4" :value="4"
                    :disabled="!allows_crud"
              >d. Definición.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._12" :checked="sad._12 == 5" :value="5"
                    :disabled="!allows_crud"
              >e. Argumentación.</b-form-checkbox
            >
          </div>
        </div>
      </div>
      <!-- Pregunta 13 -->
      <div class="m-4">
        <div class="container">
          <p class="text-justify">13. Lee el siguiente texto y responde.</p>
          <template v-if="allows_crud">
            <b-form-invalid-feedback :state="Number.isInteger(sad._13)">Debe seleccionar una opción.</b-form-invalid-feedback>
          </template>
          <p class="text-justify description-p">
            Este <u>artículo</u> presenta el análisis de contenido como método de investigación. De forma secuenciada se <u>expone</u> el concepto de análisis, se analiza <u>el problema</u> de las fuentes a la hora de investigar partiendo de todo tipo de documentos, se muestran los <u>diversos métodos</u> de análisis de documentos, prestando especial interés al análisis de <u>contenido</u> y <u>a la importancia que, en éste, tiene la inferencia</u>. En la última parte del artículo se muestra cómo se lleva a cabo un caso en particular. Concretamente una de las fases de las que constaba una investigación que se ha desarrollado en 2000 sobre la <u>“formación de animadores y educadores sociales en España”</u> y que exponemos sintéticamente.
            <br />
            <strong>Fuente: </strong>
            <em class="text-primary"> López Noguero, F. (2002). El análisis de contenido como método de investigación. Revista de Educación, 4 (2002): 167-179.</em
            >
          </p>
          <p class="text-justify">Las palabras y enunciados subrayados hacen referencia a:</p>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._13" :checked="sad._13 == 1" :value="1"
                    :disabled="!allows_crud"
              >a. Descripción.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._13" :checked="sad._13 == 2" :value="2"
                    :disabled="!allows_crud"
              >b. Clasificación.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._13" :checked="sad._13 == 3" :value="3"
                    :disabled="!allows_crud"
              >c. Comparación.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._13" :checked="sad._13 == 4" :value="4"
                    :disabled="!allows_crud"
              >d. Definición.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._13" :checked="sad._13 == 5" :value="5"
                    :disabled="!allows_crud"
              >e. Argumentación.</b-form-checkbox
            >
          </div>
        </div>
      </div>
      <!-- Pregunta 14 -->
      <div class="m-4">
        <div class="container">
          <p class="text-justify">14. Lee el siguiente texto y responde.</p>
          <template v-if="allows_crud">
            <b-form-invalid-feedback :state="Number.isInteger(sad._14)">Debe seleccionar una opción.</b-form-invalid-feedback>
          </template>
          <p class="text-justify description-p">
            <u>La piel</u> es la capa superficial que nos cubre a todos y el color y grosor <u>cambia según</u> la raza, pues en las <u>zonas frías</u> la piel suele <u>ser más clara</u>, tanto <u>por estar cubierta</u> como <u>por razones genéticas</u>; <u>en cambio</u> en las <u>zonas tropicales</u> la piel <u>se torna más obscura</u>. <u>Es así como</u>, cuando una persona de piel blanca se broncea por mucho tiempo su piel se torna más obscura, al igual que una persona de piel obscura suele reducir su color cuando se encuentra <u>alejada del sol</u> durante mucho tiempo.
            <br />
            <strong>Fuente: </strong>
            <em class="text-primary"> http://www.ejemplode.com</em
            >
          </p>
          <p class="text-justify">Las palabras subrayadas son ejemplos de frases:</p>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._14" :checked="sad._14 == 1" :value="1"
                    :disabled="!allows_crud"
              >a. Argumentativas.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._14" :checked="sad._14 == 2" :value="2"
                    :disabled="!allows_crud"
              >b. Conceptuales.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._14" :checked="sad._14 == 3" :value="3"
                    :disabled="!allows_crud"
              >c. Clasificatorias.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._14" :checked="sad._14 == 4" :value="4"
                    :disabled="!allows_crud"
              >d. Nominativas.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._14" :checked="sad._14 == 5" :value="5"
                    :disabled="!allows_crud"
              >e. Comparativas.</b-form-checkbox
            >
          </div>
        </div>
      </div>
      <!-- Pregunta 15 -->
      <div class="m-4">
        <div class="container">
          <p class="text-justify">15. Lee el siguiente texto y responde.</p>
          <template v-if="allows_crud">
            <b-form-invalid-feedback :state="Number.isInteger(sad._15)">Debe seleccionar una opción.</b-form-invalid-feedback>
          </template>
          <p class="text-justify description-p">
            Margaret parecía menos una niña viva que una muñeca de porcelana, menudita, silenciosa, con sus ojos de añil lavado y sus cabellos de lacia plata brillante. Traía su vestidito de franela tan sucio como sus zapatos remendados. Llegó aupada en los recios y tatuados brazos de Eugen, de cuya cara huesuda goteaba el sudor sobre las rodillas de su hija.
            <br />
            <strong>Fuente: </strong>
            <em class="text-primary"> https://www.aboutespanol.com</em
            >
          </p>
          <p class="text-justify">El texto corresponde a una:</p>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._15" :checked="sad._15 == 1" :value="1"
                    :disabled="!allows_crud"
              >a. Descripción.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._15" :checked="sad._15 == 2" :value="2"
                    :disabled="!allows_crud"
              >b. Denominación.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._15" :checked="sad._15 == 3" :value="3"
                    :disabled="!allows_crud"
              >c. Enumeración.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._15" :checked="sad._15 == 4" :value="4"
                    :disabled="!allows_crud"
              >d. Argumentación.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._15" :checked="sad._15 == 5" :value="5"
                    :disabled="!allows_crud"
              >e. Definición.</b-form-checkbox
            >
          </div>
        </div>
      </div>
      <!-- Pregunta 16 -->
      <div class="m-4">
        <div class="container">
          <p class="text-justify">16. Lee el siguiente texto y responde.</p>
          <template v-if="allows_crud">
            <b-form-invalid-feedback :state="Number.isInteger(sad._16)">Debe seleccionar una opción.</b-form-invalid-feedback>
          </template>
          <p class="text-justify description-p">
            Se les denomina leyes científicas a cada una de las relaciones existentes entre las diversas magnitudes que intervienen en un fenómeno. Las leyes científicas son aquellas que poseen una descripción de un comportamiento constante, que se puede observar en determinados fenómenos de la naturaleza.
            <br />
            <br />
            Se caracterizan por usar un lenguaje cognitivo e indicativo, enunciando si algo es o no es. Indican si una proposición es verdadera o falsa siendo de carácter comprobatorio, entendiéndose que el ser humano realiza observaciones de la naturaleza y las describe, realizando comprobaciones (y en los casos que lo permiten, experimentaciones) con las que va obteniendo hipótesis que derivan en teorías y estas a su vez, luego de ser comprobadas en varias ocasiones, mediando diversos factores o variables, que representan parcial e indirectamente, una medición de fenómenos concretos, se le da la denominación de “Ley” científica.
            <br />
            <strong>Fuente: </strong>
            <em class="text-primary"> http://redined.mecd.gob.es/xmlui/bitstream/handle/11162/80304/00820103011196.pdf?sequence=1</em
            >
          </p>
          <p class="text-justify">El texto consiste en una:</p>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._16" :checked="sad._16 == 1" :value="1"
                    :disabled="!allows_crud"
              >a. Clasificación argumentada.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._16" :checked="sad._16 == 2" :value="2"
                    :disabled="!allows_crud"
              >b. Comparación argumentada.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._16" :checked="sad._16 == 3" :value="3"
                    :disabled="!allows_crud"
              >c. Descripción argumentada.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._16" :checked="sad._16 == 4" :value="4"
                    :disabled="!allows_crud"
              >d. Definición argumentada.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._16" :checked="sad._16 == 5" :value="5"
                    :disabled="!allows_crud"
              >e. Información argumentada.</b-form-checkbox
            >
          </div>
        </div>
      </div>
      <!-- Pregunta 17 -->
      <div class="m-4">
        <div class="container">
          <p class="text-justify">17. ¿Cómo auto evalúas tu desempeño en la solución de las preguntas 2 a 16? Siendo 1 la menor valoración y 5 la mayor valoración.</p>
          <template v-if="allows_crud">
            <b-form-invalid-feedback :state="Number.isInteger(sad._17)">Debe seleccionar una opción.</b-form-invalid-feedback>
          </template>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._17" :checked="sad._17 == 1" :value="1"
                    :disabled="!allows_crud"
              >a. 1.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._17" :checked="sad._17 == 2" :value="2"
                    :disabled="!allows_crud"
              >b. 2.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._17" :checked="sad._17 == 3" :value="3"
                    :disabled="!allows_crud"
              >c. 3.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._17" :checked="sad._17 == 4" :value="4"
                    :disabled="!allows_crud"
              >d. 4.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._17" :checked="sad._17 == 5" :value="5"
                    :disabled="!allows_crud"
              >e. 5.</b-form-checkbox
            >
          </div>
        </div>
      </div>
      <h3>SAD Cognoscente Verbal</h3>
      <h5>
        Estas preguntas se orientan a conocer el desarrollo de tus
        habilidades verbales.
      </h5>
      <!-- Pregunta 18 -->
      <div class="m-4">
        <div class="container">
          <p class="text-justify">18. Para resolver un ejercicio es necesario tomar en cuenta los siguientes elementos:</p>
          <template v-if="allows_crud">
            <b-form-invalid-feedback :state="Number.isInteger(sad._18)">Debe seleccionar una opción.</b-form-invalid-feedback>
          </template>
          <p class="text-justify">Leer completo el ejercicio -Tener presente si es un ejercicio conocido o nuevo - Precisar los datos con que se cuenta - Proyectar cómo solucionar el ejercicio.
          <br />
          <br />
          Las acciones indicadas anteriormente son propias de:</p>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._18" :checked="sad._18 == 1" :value="1"
                    :disabled="!allows_crud"
              >a. Las orientaciones en la solución del ejercicio.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._18" :checked="sad._18 == 2" :value="2"
                    :disabled="!allows_crud"
              >b. La comprobación sobre los resultados.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._18" :checked="sad._18 == 3" :value="3"
                    :disabled="!allows_crud"
              >c. La operatoria de solución del ejercicio.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._18" :checked="sad._18 == 4" :value="4"
                    :disabled="!allows_crud"
              >d. La aplicación de las vías de solución.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._18" :checked="sad._18 == 5" :value="5"
                    :disabled="!allows_crud"
              >e. La retroalimentación sobre la ejecución.</b-form-checkbox
            >
          </div>
        </div>
      </div>
      <!-- Pregunta 19 -->
      <div class="m-4">
        <div class="container">
          <p class="text-justify">19. En la solución de un problema los pasos a seguir de <u>forma consecutiva</u> son:</p>
          <template v-if="allows_crud">
            <b-form-invalid-feedback :state="Number.isInteger(sad._19)">Debe seleccionar una opción.</b-form-invalid-feedback>
          </template>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._19" :checked="sad._19 == 1" :value="1"
                    :disabled="!allows_crud"
              >a. Ejecución de las vías para resolver el problema – Orientación en las condiciones para solucionar el problema – Elección de la vía de solución del problema – Control sobre el proceso.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._19" :checked="sad._19 == 2" :value="2"
                    :disabled="!allows_crud"
              >b. Elección de la vía de solución del problema - Ejecución de las vías para resolver el problema – Orientación en las condiciones para solucionar el problema – Control sobre el proceso.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._19" :checked="sad._19 == 3" :value="3"
                    :disabled="!allows_crud"
              >c. Orientación en las condiciones para solucionar el problema – Elección de la vía de solución del problema – Ejecución de las vías para resolver el problema – Control sobre el proceso.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._19" :checked="sad._19 == 4" :value="4"
                    :disabled="!allows_crud"
              >d. Ejecución de las vías para resolver el problema – Orientación en las condiciones para solucionar el problema – Elección de la vía de solución del problema sobre el proceso.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._19" :checked="sad._19 == 5" :value="5"
                    :disabled="!allows_crud"
              >e. Control sobre lo que se va realizar – Ejecución de las vías para resolver el problema – Orientación en las condiciones de solución del problema – Elección de la vía de solución del problema – Control sobre el proceso.</b-form-checkbox
            >
          </div>
        </div>
      </div>
      <!-- Pregunta 20 -->
      <div class="m-4">
        <div class="container">
          <p class="text-justify">20. A continuación, se presentan tres alternativas para abordar un proyecto.</p>
          <template v-if="allows_crud">
            <b-form-invalid-feedback :state="Number.isInteger(sad._20)">Debe seleccionar una opción.</b-form-invalid-feedback>
          </template>
          <p class="text-justify">
            <strong>Proyecto A</strong><br />
              I. Fundamentación.<br />
              II. Análisis de factibilidad.<br />
              III. Estrategias de ejecución.<br />
              IV. Cronograma de ejecución.<br />
              V. Resultados proyectados.
          </p>
          <p class="text-justify">
            <strong>Proyecto B</strong><br />
              I. Estrategias de ejecución.<br />
              II. Fundamentación.<br />
              III. Cronograma de ejecución.<br />
              IV. Análisis de factibilidad.<br />
              V. Resultados proyectados.<br />
          </p>
          <p class="text-justify">
            <strong>Proyecto C</strong><br />
              I. Cronograma de ejecución.<br />
              II. Estrategias de ejecución.<br />
              III. Fundamentación.<br />
              IV. Análisis de factibilidad.<br />
              V. Resultados proyectados.
          </p>
          <p class="text-justify">
              Marca la alternativa correcta <strong><u>permitiría su ejecución</u></strong>:
          </p>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._20" :checked="sad._20 == 1" :value="1"
                    :disabled="!allows_crud"
              >a. El proyecto A porque ante un problema a solucionar, debo dominar el estado del arte y su factibilidad.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._20" :checked="sad._20 == 2" :value="2"
                    :disabled="!allows_crud"
              >b. El proyecto B porque lo primero a tener claro es la estrategia de ejecución.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._20" :checked="sad._20 == 3" :value="3"
                    :disabled="!allows_crud"
              >c. El proyecto C porque debo analizar la factibilidad de su ejecución desde el punto de vista de los tiempos.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._20" :checked="sad._20 == 4" :value="4"
                    :disabled="!allows_crud"
              >d. Tanto el Proyecto B como el C consideran consecutivamente los pasos necesarios para evaluar la factibilidad del proyecto.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._20" :checked="sad._20 == 5" :value="5"
                    :disabled="!allows_crud"
              >e. El proyecto A, B y C consideran como paso esencial los resultados proyectados.</b-form-checkbox
            >
          </div>
        </div>
      </div>
      <!-- Pregunta 21 -->
      <div class="m-4">
        <div class="container">
          <p class="text-justify">21. ¿Cuál(es) del o los conjunto(s) de palabras rompe(n) el criterio de agrupación?</p>
          <template v-if="allows_crud">
            <b-form-invalid-feedback :state="Number.isInteger(sad._21)">Debe seleccionar una opción.</b-form-invalid-feedback>
          </template>
          <p class="text-justify">
              Grupo 1: camisa, vestido, pantalón, blusa.<br />
              Grupo 2: Perro, caballo, niño, hombre.<br />
              Grupo 3: Leche, tazón, queso, mantequilla.<br />
              Grupo 4: Silla, armario, mesa, cama.<br />
              Grupo 5: Amor, odio, tristeza, melancolía.
          </p>
          <p class="text-justify">
              Marca la alternativa correcta <strong><u>permitiría su ejecución</u></strong>:
          </p>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._21" :checked="sad._21 == 1" :value="1"
                    :disabled="!allows_crud"
              >a. Grupo 1 y 2.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._21" :checked="sad._21 == 2" :value="2"
                    :disabled="!allows_crud"
              >b. Sólo grupo 2.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._21" :checked="sad._21 == 3" :value="3"
                    :disabled="!allows_crud"
              >c. Sólo grupo 3.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._21" :checked="sad._21 == 4" :value="4"
              >d. Grupo 3 y 4.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._21" :checked="sad._21 == 5" :value="5"
              >e. Grupo 1 y 5.</b-form-checkbox
            >
          </div>
        </div>
      </div>
      <!-- Pregunta 22 -->
      <div class="m-4">
        <div class="container">
          <p class="text-justify">22. VERDE es a NEGRO como HIERBA es a:</p>
          <template v-if="allows_crud">
            <b-form-invalid-feedback :state="Number.isInteger(sad._22)">Debe seleccionar una opción.</b-form-invalid-feedback>
          </template>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._22" :checked="sad._22 == 1" :value="1"
                    :disabled="!allows_crud"
              >a. Pasto.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._22" :checked="sad._22 == 2" :value="2"
                    :disabled="!allows_crud"
              >b. Café.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._22" :checked="sad._22 == 3" :value="3"
                    :disabled="!allows_crud"
              >c. Paja.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._22" :checked="sad._22 == 4" :value="4"
                    :disabled="!allows_crud"
              >d. Carbón.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._22" :checked="sad._22 == 5" :value="5"
                    :disabled="!allows_crud"
              >e. Leña.</b-form-checkbox
            >
          </div>
        </div>
      </div>
      <!-- Pregunta 23 -->
      <div class="m-4">
        <div class="container">
          <p class="text-justify">23. HOY es a AYER como PASADO MAÑANA es a:</p>
          <template v-if="allows_crud">
            <b-form-invalid-feedback :state="Number.isInteger(sad._23)">Debe seleccionar una opción.</b-form-invalid-feedback>
          </template>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._23" :checked="sad._23 == 1" :value="1"
                    :disabled="!allows_crud"
              >a. Mañana.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._23" :checked="sad._23 == 2" :value="2"
                    :disabled="!allows_crud"
              >b. Hoy.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._23" :checked="sad._23 == 3" :value="3"
                    :disabled="!allows_crud"
              >c. Antes de ayer.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._23" :checked="sad._23 == 4" :value="4"
                    :disabled="!allows_crud"
              >d. Ayer.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._23" :checked="sad._23 == 5" :value="5"
                    :disabled="!allows_crud"
              >e. Pasado mañana.</b-form-checkbox
            >
          </div>
        </div>
      </div>
      <!-- Pregunta 24 -->
      <div class="m-4">
        <div class="container">
          <p class="text-justify">24. NORTE es a EUROPA como SUR es a:</p>
          <template v-if="allows_crud">
            <b-form-invalid-feedback :state="Number.isInteger(sad._24)">Debe seleccionar una opción.</b-form-invalid-feedback>
          </template>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._24" :checked="sad._24 == 1" :value="1"
                    :disabled="!allows_crud"
              >a. Alemania.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._24" :checked="sad._24 == 2" :value="2"
                    :disabled="!allows_crud"
              >b. Roma.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._24" :checked="sad._24 == 3" :value="3"
                    :disabled="!allows_crud"
              >c. Australia.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._24" :checked="sad._24 == 4" :value="4"
                    :disabled="!allows_crud"
              >d. Nueva York.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._24" :checked="sad._24 == 5" :value="5"
                    :disabled="!allows_crud"
              >e. Suecia.</b-form-checkbox
            >
          </div>
        </div>
      </div>
      <!-- Encabezado de Preguntas de la 25 a la 27 -->
      <div class="m-4">
        <div class="container">
          <p class="text-justify">Lee el siguiente relato y luego responde las tres preguntas 25 a 27.</p>
          <p class="text-justify description-p">
            A Ema le gusta construir bloques con cubos pequeños como el que se muestra en la siguiente imagen. <br />

            <br />
              Ema tiene muchos cubos pequeños como éste. Utiliza pegamento para unir los cubos y construir otros bloques. Primero Ema pega ocho cubos para hacer el bloque que se muestra en el gráfico A:
            <br />
              Luego Ema hace los bloques macizos que se muestran en los gráficos B y C
            <br />

          </p>
        </div>
      </div>
      <!-- Pregunta 25 -->
      <div class="m-4">
        <div class="container">
          <p class="text-justify">25. ¿Cuántos cubos pequeños necesitará Ema para hacer el bloque que se muestra en el gráfico B?</p>
          <template v-if="allows_crud">
            <b-form-invalid-feedback :state="Number.isInteger(sad._25)">Debe seleccionar una opción.</b-form-invalid-feedback>
          </template>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._25" :checked="sad._25 == 1" :value="1"
                    :disabled="!allows_crud"
              >a. 4.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._25" :checked="sad._25 == 2" :value="2"
                    :disabled="!allows_crud"
              >b. 6.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._25" :checked="sad._25 == 3" :value="3"
                    :disabled="!allows_crud"
              >c. 9.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._25" :checked="sad._25 == 4" :value="4"
                    :disabled="!allows_crud"
              >d. 12.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._25" :checked="sad._25 == 5" :value="5"
                    :disabled="!allows_crud"
              >e. 16.</b-form-checkbox
            >
          </div>
        </div>
      </div>
      <!-- Pregunta 26 -->
      <div class="m-4">
        <div class="container">
          <p class="text-justify">26. ¿Cuántos cubos pequeños necesitará Ema para construir el bloque macizo que se muestra en el gráfico C?</p>
          <template v-if="allows_crud">
            <b-form-invalid-feedback :state="Number.isInteger(sad._26)">Debe seleccionar una opción.</b-form-invalid-feedback>
          </template>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._26" :checked="sad._26 == 1" :value="1"
                    :disabled="!allows_crud"
              >a. 9.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._26" :checked="sad._26 == 2" :value="2"
                    :disabled="!allows_crud"
              >b. 18.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._26" :checked="sad._26 == 3" :value="3"
                    :disabled="!allows_crud"
              >c. 27.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._26" :checked="sad._26 == 4" :value="4"
                    :disabled="!allows_crud"
              >d. 37.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._26" :checked="sad._26 == 5" :value="5"
                    :disabled="!allows_crud"
              >e. 45.</b-form-checkbox
            >
          </div>
        </div>
      </div>
      <!-- Pregunta 27 -->
      <div class="m-4">
        <div class="container">
          <p class="text-justify description-p">
            Ema se da cuenta de que ha utilizado más cubos pequeños de los que realmente necesitaba para hacer un bloque como el que se muestra en el gráfico C. Se da cuenta de que podía haber construido un bloque como el del gráfico C pegando los cubos pequeños, pero dejándolo hueco por dentro.</p>
          <p class="text-justify">27. ¿Cuál es el mínimo número de cubos que necesita para hacer un bloque como el que se muestra en el gráfico C, pero hueco?</p>
          <template v-if="allows_crud">
            <b-form-invalid-feedback :state="Number.isInteger(sad._27)">Debe seleccionar una opción.</b-form-invalid-feedback>
          </template>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._27" :checked="sad._27 == 1" :value="1"
                    :disabled="!allows_crud"
              >a. 12.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._27" :checked="sad._27 == 2" :value="2"
                    :disabled="!allows_crud"
              >b. 18.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._27" :checked="sad._27 == 3" :value="3"
                    :disabled="!allows_crud"
              >c. 26.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._27" :checked="sad._27 == 4" :value="4"
                    :disabled="!allows_crud"
              >d. 35.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._27" :checked="sad._27 == 5" :value="5"
                    :disabled="!allows_crud"
              >e. 44.</b-form-checkbox
            >
          </div>
        </div>
      </div>
      <!-- Pregunta 28 -->
      <div class="m-4">
        <div class="container">
          <p class="text-justify">28. ¿Cómo auto evalúas tu desempeño en la solución de las preguntas 18 a 27?</p>
          <template v-if="allows_crud">
            <b-form-invalid-feedback :state="Number.isInteger(sad._28)">Debe seleccionar una opción.</b-form-invalid-feedback>
          </template>
          <p class="text-justify">Siendo 1 la menor valoración y 5 la mayor valoración</p>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._28" :checked="sad._28 == 1" :value="1"
                    :disabled="!allows_crud"
              >a. 1.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._28" :checked="sad._28 == 2" :value="2"
                    :disabled="!allows_crud"
              >b. 2.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._28" :checked="sad._28 == 3" :value="3"
                    :disabled="!allows_crud"
              >c. 3.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._28" :checked="sad._28 == 4" :value="4"
                    :disabled="!allows_crud"
              >d. 4.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._28" :checked="sad._28 == 5" :value="5"
                    :disabled="!allows_crud"
              >e. 5.</b-form-checkbox
            >
          </div>
        </div>
      </div>
      <h3>SAD Retroalimentación</h3>
      <h5>Estas preguntas se orientan a evaluar tu capacidad de atención, autoevaluación y autovaloración mediante los siguientes enunciados.</h5>
      <!-- Pregunta 29 -->
      <div class="m-4">
        <div class="container">
          <p class="text-justify">29. Identifica cuántas veces aparece en el siguiente recuadro la serie <strong> d'd” </strong> y luego señale ¿Cuántas series iguales encontró?</p>
          <template v-if="allows_crud">
            <b-form-invalid-feedback :state="Number.isInteger(sad._29)">Debe seleccionar una opción.</b-form-invalid-feedback>
          </template>
          <p class="text-justify">Siendo 1 la menor valoración y 5 la mayor valoración</p>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._29" :checked="sad._29 == 1" :value="1"
                    :disabled="!allows_crud"
              >a. 3.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._29" :checked="sad._29 == 2" :value="2"
                    :disabled="!allows_crud"
              >b. 4.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._29" :checked="sad._29 == 3" :value="3"
                    :disabled="!allows_crud"
              >c. 5.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._29" :checked="sad._29 == 4" :value="4"
                    :disabled="!allows_crud"
              >d. 6.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._29" :checked="sad._29 == 5" :value="5"
                    :disabled="!allows_crud"
              >e. 7.</b-form-checkbox
            >
          </div>
        </div>
      </div>
      <!-- Pregunta 30 -->
      <div class="m-4">
        <div class="container">
          <p class="text-justify">30. ¿Cuántas series iguales a esta: <strong> A z 4 R 2 ñ </strong> encuentras en el cuadro?</p>
          <template v-if="allows_crud">
            <b-form-invalid-feedback :state="Number.isInteger(sad._30)">Debe seleccionar una opción.</b-form-invalid-feedback>
          </template>
          <p class="text-justify">Siendo 1 la menor valoración y 5 la mayor valoración</p>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._30" :checked="sad._30 == 1" :value="1"
                    :disabled="!allows_crud"
              >a. 6.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._30" :checked="sad._30 == 2" :value="2"
                    :disabled="!allows_crud"
              >b. 8.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._30" :checked="sad._30 == 3" :value="3"
                    :disabled="!allows_crud"
              >c. 10.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._30" :checked="sad._30 == 4" :value="4"
                    :disabled="!allows_crud"
              >d. 11.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._30" :checked="sad._30 == 5" :value="5"
                    :disabled="!allows_crud"
              >e. 14.</b-form-checkbox
            >
          </div>
        </div>
      </div>
      <!-- Pregunta 31 -->
      <div class="m-4">
        <div class="container">
          <p class="text-justify">31. ¿Cuántas figuras faltan en la imagen B con respecto a la imagen A?</p>
          <template v-if="allows_crud">
            <b-form-invalid-feedback :state="Number.isInteger(sad._31)">Debe seleccionar una opción.</b-form-invalid-feedback>
          </template>
          <p class="text-justify">Siendo 1 la menor valoración y 5 la mayor valoración</p>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._31" :checked="sad._31 == 1" :value="1"
                    :disabled="!allows_crud"
              >a. 5.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._31" :checked="sad._31 == 2" :value="2"
                    :disabled="!allows_crud"
              >b. 7.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._31" :checked="sad._31 == 3" :value="3"
                    :disabled="!allows_crud"
              >c. 9.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._31" :checked="sad._31 == 4" :value="4"
                    :disabled="!allows_crud"
              >d. 13.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._31" :checked="sad._31 == 5" :value="5"
                    :disabled="!allows_crud"
              >e. 15.</b-form-checkbox
            >
          </div>
        </div>
      </div>
      <!-- Pregunta 32 -->
      <div class="m-4">
        <div class="container">
          <p class="text-justify">32. ¿Cómo auto evalúas tu desempeño en la solución de las preguntas <strong> 29 a la 31</strong>? Siendo 1 la menor valoración y 5 la mayor valoración.</p>
          <template v-if="allows_crud">
            <b-form-invalid-feedback :state="Number.isInteger(sad._32)">Debe seleccionar una opción.</b-form-invalid-feedback>
          </template>
          <p class="text-justify">Siendo 1 la menor valoración y 5 la mayor valoración</p>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._32" :checked="sad._32 == 1" :value="1"
                    :disabled="!allows_crud"
              >a. 1.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._32" :checked="sad._32 == 2" :value="2"
                    :disabled="!allows_crud"
              >b. 2.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._32" :checked="sad._32 == 3" :value="3"
                    :disabled="!allows_crud"
              >c. 3.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._32" :checked="sad._32 == 4" :value="4"
                    :disabled="!allows_crud"
              >d. 4.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._32" :checked="sad._32 == 5" :value="5"
                    :disabled="!allows_crud"
              >e. 5.</b-form-checkbox
            >
          </div>
        </div>
      </div>
      <!-- Pregunta 33 -->
      <div class="m-4">
        <div class="container">
          <p class="text-justify">33. Califica el éxito en tus estudios, donde el 1 es el nivel más bajo y el 5 el más alto.</p>
          <template v-if="allows_crud">
            <b-form-invalid-feedback :state="Number.isInteger(sad._33)">Debe seleccionar una opción.</b-form-invalid-feedback>
          </template>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._33" :checked="sad._33 == 1" :value="1"
                    :disabled="!allows_crud"
              >a. 1.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._33" :checked="sad._33 == 2" :value="2"
                    :disabled="!allows_crud"
              >b. 2.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._33" :checked="sad._33 == 3" :value="3"
                    :disabled="!allows_crud"
              >c. 3.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._33" :checked="sad._33 == 4" :value="4"
                    :disabled="!allows_crud"
              >d. 4.</b-form-checkbox
            >
          </div>
          <div class="text-justify">
            <b-form-checkbox v-model="sad._33" :checked="sad._33 == 5" :value="5"
                    :disabled="!allows_crud"
              >e. 5.</b-form-checkbox
            >
          </div>
        </div>
      </div>
      <!-- ... -->
      <template v-if="allows_crud">
        <b-button @click="save" class="mx-1">Guardar</b-button>
        <b-button @click="close" class="mx-1">Terminar</b-button>
      </template>
    </div>
  </div>
</template>

<script>
import { generateUniqueId, toast } from "@/utils/utils";
import { validationMixin } from "vuelidate";
import { required, minValue, maxValue } from "vuelidate/lib/validators";

export default {
  name: "SAD",
  mixins: [validationMixin],
  props: {
    User: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sad: {
        user: this.User.id,
        id: generateUniqueId(),
        _1_1: null,
        _1_2: null,
        _1_3: null,
        _1_4: null,
        _1_5: null,
        _1_6: null,
        _1_7: null,
        _1_8: null,
        _1_9: null,
        _1_10: null,
        _1_11: null,
        _1_12: null,
        _1_13: null,
        _1_14: null,
        _1_15: null,
        _1_16: null,
        _1_17: null,
        _1_18: null,
        _2: null,
        _3: null,
        _4: null,
        _5: null,
        _6: null,
        _7: null,
        _8: null,
        _9: null,
        _10: null,
        _11: null,
        _12: null,
        _13: null,
        _14: null,
        _15: null,
        _16: null,
        _17: null,
        _18: null,
        _19: null,
        _20: null,
        _21: null,
        _22: null,
        _23: null,
        _24: null,
        _25: null,
        _26: null,
        _27: null,
        _28: null,
        _29: null,
        _30: null,
        _31: null,
        _32: null,
        _33: null,
        show: true,
        closed: false,
      },
      allows_crud: true,
    };
  },
  validations() {
    return {
      sad: {
        text: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _1_1: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _1_2: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _1_3: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _1_4: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _1_5: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _1_6: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _1_7: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _1_8: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _1_9: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _1_10: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _1_11: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _1_12: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _1_13: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _1_14: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _1_15: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _1_16: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _1_17: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _1_18: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _2: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _3: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _4: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _5: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _6: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _7: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _8: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _9: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _10: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _11: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _12: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _13: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _14: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _15: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _16: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _17: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _18: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _19: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _20: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _21: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _22: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _23: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _24: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _25: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _26: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _27: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _28: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _29: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _30: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _31: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _32: { required, minValue: minValue(1), maxValue: maxValue(5) },
        _33: { required, minValue: minValue(1), maxValue: maxValue(5) },
      },
    };
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.sad[name];
      return $dirty ? !$error : null;
    },
    fetchSAD() {
      this.$restful.Get(`/cideu/sad/?user=${this.User.id}`).then((response) => {
        if (response.length == 1) {
          this.sad = response[0];
        } else if (response.length > 1)
          console.log("Hay más de un Perfil de Ingreso para este usuario.");
      });
    },
    close() {
      this.$v.sad.$touch();
      if (this.$v.sad.$anyError) {
        this.$swal.fire("Hay errores que debe correjir.");
        return;
      }
      this.sad.closed = true;
      this.save();
    },
    save() {
      if (isNaN(this.sad.id)) this.create();
      else this.update();
    },
    create() {
      this.$restful.Post(`/cideu/sad/`, this.sad).then((response) => {
        this.sad = response;
        toast("Formulario guardado.");
      });
    },
    update() {
      this.$restful
        .Put(`/cideu/sad/${this.sad.id}/`, this.sad)
        .then((response) => {
          console.log(response);
          this.sad = response;
          toast("Formulario guardado.");
        });
    },
  },
  watch: {
    "sad.closed": function () {
      if (!this.sad.closed) {
        this.allows_crud = true;
      } else {
        this.allows_crud = false;
      }
    },
  },
  created() {
    this.fetchSAD();
  },
};
</script>

<style scoped>
.description-p {
  border: 1px solid gray;
  padding-right: 1em;
  padding-left: 1em;
  background-color: rgb(226, 226, 226);
}
table,
td,
th {
  border: 1px solid black;
}
td {
  padding-left: 0.4rem;
}
</style>